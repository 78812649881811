<template>
    <div class="vacancies">
        <MainHeader
            class="vacancies__header"
            title="Вакансии"
            v-if="vacanciesList.vacancies"
            :number="vacanciesList.vacancies.length ? vacanciesList.vacancies_count : ''"
            btnText="Новая вакансия"
            @btnClick="showPopup = true"
        />
        <div class="vacancies__row">
            <VSegmentedControl
                :items="filteringOptions"
                :modelValue="activeFilter"
                @update:modelValue="filtration"
                class="vacancies__segmented-control"
            />

            <VDefaultInput
                v-model="search"
                @update:modelValue="vacanciesSearch"
                placeholder="Введите название вакансии"
                border
                class="vacancies__search"
            >
                <template #iconLeft>
                    <img src="@/assets/images/icons/search.svg" alt="search"/>
                </template>
                <template #iconRight>
                    <div
                        class="companies__search-clear"
                        v-if="search"
                        @click="clearSearch"
                    >
                        <img src="@/assets/images/icons/x.svg" alt="search"/>
                    </div>
                </template>
            </VDefaultInput>
        </div>

        <div class="vacancies__no-content" v-if="errorGet">
            <VNoContent
                title="Ошибка"
                description="Произошла ошибка. Пожалуйста, попробуйте позже"
            />
        </div>

        <div class="vacancies__content" v-if="!errorGet && vacanciesList.vacancies">
            <div class="vacancies__cards-wrapper" v-if="vacanciesList.vacancies.length">
                <div class="vacancies__cards">
                    <VacancyCard class="vacancies__card"
                                 v-for="vacancy in vacanciesList.vacancies"
                                 :key="vacancy.id"
                                 :vacancy="vacancy"
                                 :draft="activeFilter === 'draft'"/>
                </div>
            </div>

            <div class="vacancies__no-content" v-if="!vacanciesList.vacancies.length">
                <VNoContent
                    v-if="!search"
                    title="Список вакансий пуст"
                    description="По выбранному фильтру ничего не найдено"
                />
                <VNoContent
                    v-if="search"
                    title="Ничего не найдено"
                    description="Проверьте пожалуйста, правильно ли вы написали название"
                />
            </div>

            <div
                class="vacancies__pagination"
                v-if="vacanciesList.pages_count > 1 && vacanciesList.vacancies.length"
            >
                <VButton
                    class="vacancies__pagination-btn"
                    label="Загрузить еще"
                    bg="#0D5FCB1A"
                    color="#0D5FCB"
                    bgHover="#0D5FCB4D"
                    colorHover="#0D5FCB"
                    @click="loadMore()"
                    v-if="page < vacanciesList.pages_count && showPagination"
                />
                <VPaginate
                    :pageCount="vacanciesList.pages_count"
                    :modelValue="page"
                    @update:modelValue="pagination"
                    class="vacancies__pagination-pag"
                />
            </div>
        </div>

        <ModalWindow
            v-if="showPopup"
            @close="showPopup = false"
            class="vacancies__modal"
        >
            <NewVacancy @close="showPopup = false"/>
        </ModalWindow>
    </div>
    <AccessClosed v-if="vacanciesList.blocked_by_contract"/>
</template>

<script setup>
import {ref, onMounted, onActivated} from "vue";
import {useRoute, useRouter} from "vue-router";
import NewVacancy from "@/components/ModalWindow/ModalBodyes/NewVacancy";
import VPaginate from "@/components/UI/VPaginate";
import vacancies from "@/api/vacancies";
import MainHeader from "@/components/MainHeader";
import VacancyCard from "@/components/VacancyCard"
import AccessClosed from "@/components/AccessClosed"

const router = useRouter()
const route = useRoute()

const filteringOptions = [
    {
        id: "open",
        label: "Открытые",
    },
    {
        id: "draft",
        label: "Черновик",
    }
];

const errorGet = ref(false)

const activeFilter = ref("open");
const search = ref("");
const page = ref(1);
const showPagination = ref(true)
const showPopup = ref(false)
const showCards = ref(true)


const vacanciesList = ref({})
const getVacancies = async (params) => {
    try {
        vacanciesList.value = await vacancies.getVacancies(params)
    } catch (error) {
        console.log(error)
    }
}

const loadMore = async (num) => {
    showPagination.value = false
    page.value = num ? num : page.value + 1;

    const params = {
        search: search.value ? search.value : "",
        page: page.value,
    };

    if (activeFilter.value === "draft") params.is_draft = 1

    try {
        const response = await vacancies.getVacancies(params)

        if (num) vacanciesList.value.vacancies = [];
        vacanciesList.value.vacancies.push(...response.vacancies);
        showPagination.value = true
    } catch (error) {
        errorGet.value = true
        console.log(error);
    }
};

const filtration = (val) => {
    if (val === activeFilter.value) return;
    vacanciesList.value.vacancies = []
    activeFilter.value = val;
    search.value = "";
    page.value = 1;

    if (val === "open") {
        getVacancies()
    } else if (val === "draft") {
        getVacancies({
            is_draft: 1
        })
    }
};

const vacanciesSearch = () => {
    if (search.value.length < 3) {
        const params = {
            search: '',
        };
        if (activeFilter.value === "draft") params.is_draft = 1

        getVacancies(params)

        return
    }

    const params = {
        search: search.value,
    };
    if (activeFilter.value === "draft") params.is_draft = 1

    getVacancies(params)
};

const clearSearch = () => {
    search.value = "";
    const params = {};
    if (activeFilter.value === "draft") params.is_draft = 1
    getVacancies(params)
};

const pagination = (val) => loadMore(val);

onActivated(() => {
    if (!activeFilter.value !== 'open') {
        getVacancies()
        activeFilter.value = 'open'
    }

    if (route.query.update) {
        router.push('/vacancies')
        getVacancies()
    }
})


onMounted(() => {
    getVacancies()
})
</script>

<style scoped lang="scss">
.vacancies {
    padding-bottom: 57px;

    &__header {
        margin-top: 32px;
    }

    &__row {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
    }

    &__search {
        width: 437px;

        &::v-deep(.companies__search-clear) {
            height: 20px !important;
        }

        &::v-deep(.v-input__input) {
            &::placeholder {
                color: $dark-blue;
                opacity: 1;
            }
        }


    }

    &__segmented-control {
        width: 625px;

        &::v-deep(.v-filtration__filter) {
            width: 50%;
        }
    }

    &__content {
        margin-top: 32px;
    }

    &__cards {
        margin-left: -32px;
        margin-top: -24px;
        display: flex;
        flex-wrap: wrap;
    }

    &__cards-wrapper {
        min-height: calc(100vh - 255px);
    }

    &__card {
        width: calc(33.3% - 32px);
        margin-top: 24px;
        margin-left: 32px;
    }


    &__no-content {
        width: 100%;
        height: calc(100vh - 240px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__pagination {
        position: relative;
        width: 100%;
        height: 48px;
        margin-top: 32px;
        display: flex;
        justify-content: center;
    }

    &__pagination-btn {
        width: 156px;
        padding: 0 10px;
        font-weight: 400;
        border-radius: 8px;

        &:active {
            background-color: #0D5FCB80 !important;
        }
    }

    &__pagination-pag {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__modal {
        &::v-deep(.modal__body) {
            width: 304px;
        }
    }
}
</style>
